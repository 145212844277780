<template>
  <v-card tile elevation="0" height="100%" min-height="100%">
    <v-form ref="form" v-model="isValid">
      <v-card-text class="pb-0">
        <v-text-field
          class="pb-2"
          ref="productName"
          :label="
            getTranslation('product.creationForm.formFieldTitles.name', '*')
          "
          :rules="[
            () =>
              !!product.name || $t('product.creationForm.errors.nameRequired'),
            () => !productExists || $t('product.creationForm.errors.nameTaken'),
          ]"
          required
          v-model="product.name"
          outlined
          dense
          hide-details
          :disabled="!canEditProductName"
          @keydown="validateProductExistence"
        ></v-text-field>
        <v-text-field
          class="pb-2"
          hide-details
          :label="
            getTranslation('product.creationForm.formFieldTitles.potency', '*')
          "
          :rules="[
            () =>
              !!product.potency ||
              $t('product.creationForm.errors.potencyRequired'),
          ]"
          required
          type="number"
          v-model="product.potency"
          outlined
          :disabled="!!product.id"
          @keydown="validateProductExistence"
          dense
        ></v-text-field>
        <v-text-field
          class="pb-2"
          hide-details
          :label="
            getTranslation(
              'product.creationForm.formFieldTitles.manufacturer',
              '*'
            )
          "
          :rules="[
            () =>
              !!product.manufacturer ||
              $t('product.creationForm.errors.manufacturerRequired'),
          ]"
          required
          v-model="product.manufacturer"
          outlined
          :disabled="!!product.id"
          dense
        ></v-text-field>
        <v-select
          class="pb-2"
          hide-details=""
          :items="factorTypes"
          :label="
            getTranslation(
              'product.creationForm.formFieldTitles.factorType',
              '*'
            )
          "
          required
          :rules="[
            () =>
              !!product.factorTypeId ||
              $t('product.creationForm.errors.factorTypeRequired'),
          ]"
          v-model="product.factorTypeId"
          item-text="name"
          item-value="id"
          outlined
          :disabled="!!product.id"
          dense
        ></v-select>
        <div class="d-flex pb-2">
          <v-spacer />
          <v-btn
            v-if="showSaveButton"
            color="success"
            @click="save()"
            :disabled="isSending || !isValid"
            >{{ $t("actions.save") }}</v-btn
          >
          <v-progress-circular
            v-if="isSending"
            indeterminate
          ></v-progress-circular>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text>
        <div class="d-flex">
          <h2 style="width: 50%" class="pt-2">Lot details</h2>
          <v-text-field
            v-model="search"
            :label="$t('actions.filters')"
            append-icon="mdi-magnify"
            hide-details=""
            dense
            class="pb-2"
            style="width: 50%"
          ></v-text-field>
        </div>
        <v-data-table
          v-if="showLotNumbersTable"
          :headers="headers"
          :key="this.$store.state.lotKey"
          :items="lots"
          :sort-by="['name']"
          :loading="isDataLoading"
          :loading-text="$t('app.loading')"
          :footer-props="{
            'items-per-page-options': [10, 20, 30, 40, 50, -1],
          }"
          :items-per-page="this.$store.state.itemsPerPage"
          :search="search"
          dense
        >
          <template v-slot:item.action="{ item }">
            <v-btn @click="openLotNumberDetail(item.lotNumberId)" icon>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.expiryDate="{ item }">{{
            item.expiryDate | moment("YYYY-MM-DD")
          }}</template>
          <template v-slot:item.country="{ item }">
            <span v-html="item.country"></span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-form>
    <v-snackbar
      v-model="showSnackbar"
      top
      :color="showSnackbarColor"
      style="top: 8px"
      :timeout="3000"
    >
      {{ snackBarMessage }}
      <v-btn color="white" text @click="showSnackbar = false">CLOSE</v-btn>
    </v-snackbar>
  </v-card>
</template>

<script>
import axios from "axios";
export default {
  props: ["parameter"],
  data() {
    return {
      isValid: false,
      isSending: false,
      isShowContent: false,
      showSnackbar: false,
      snackBarMessage: "SAVED",
      showSnackbarColor: "success",
      factorTypes: [],
      product: {},
      lots: [],
      search: "",
      timeout: undefined,
      productExists: 0,
      headers: [
        {
          text: this.$t("product.headerRow.action"),
          value: "action",
          class: "grey white--text",
          sortable: false,
          width: 10,
        },
        {
          text: this.$t("product.headerRow.lotNumber"),
          value: "lNumber",
          class: "grey white--text",
          width: 125,
        },
        {
          text: this.$t("product.headerRow.country"),
          value: "country",
          class: "grey white--text",
        },
        {
          text: this.$t("product.headerRow.status"),
          value: "status",
          class: "grey white--text",
        },
        // {
        //   text: this.$t("product.headerRow.htc"),
        //   value: "htc",
        //   class: "grey white--text",
        // },
        {
          text: this.$t("product.headerRow.expiryDate"),
          value: "expiryDate",
          class: "grey white--text",
          width: 125,
        },
      ],
    };
  },
  computed: {
    currentPage() {
      return this.$store.state.currentPage;
    },
    productId() {
      return this.product.id < 1 ? this.parameter.id : this.product.id;
    },
    showLotNumbersTable() {
      if (this.currentPage == "LotNumbers") {
        return true;
      }
      return this.product.id > 0;
    },
    showSaveButton() {
      var show = /*this.currentPage == "Products" && */ this.$store.getters
        .getIsAdmin;
      return show;
    },
    refreshFlag() {
      return this.$store.state.lotKey;
    },
    canEditProductName() {
      const isAdmin = this.$store.getters.getIsAdmin;

      if (isAdmin) {
        return true;
      }
      return this.product.id == null && this.product.id == 0;
    },
  },
  watch: {
    refreshFlag() {
      this.load();
    },
  },
  methods: {
    onSetTitle(title) {
      this.$emit("onSetTitle", title);
    },
    load() {
      var productId = this.product.id > 0 ? this.product.id : this.parameter.id;
      this.isDataLoading = this.$store.state.loadingBarColor;
      axios
        .get(this.$store.getters.getEnvironment + "product/" + productId)
        .then((response) => {
          this.product = response.data;
          this.isDataLoading = false;
          this.loadLotNumber();
        })
        .catch((e) => {});
    },
    getTranslation(translationPath, ...extra) {
      return `${this.$t(translationPath)}${extra.reduce(
        (acc, cur) => "" + acc + cur,
        ""
      )}`;
    },
    loadFactorType() {
      this.isDataLoading = this.$store.state.loadingBarColor;
      axios
        .get(this.$store.getters.getEnvironment + "factorType/")
        .then((response) => {
          this.factorTypes = response.data;
          this.isDataLoading = false;
          if (this.parameter.id != -1) {
            this.load();
          }
        })
        .catch((e) => {});
    },
    loadLotNumber() {
      this.isDataLoading = this.$store.state.loadingBarColor;
      axios
        .get(
          this.$store.getters.getEnvironment +
            "lotnumber/getbyproductid/" +
            this.product.id
        )
        .then((response) => {
          this.lots = response.data;
          for (let i = 0; i < this.lots.length; i++) {
            this.lots[i].country = this.$t(
              "country." + this.lots[i].countryCode
            );
          }
          this.isDataLoading = false;
        })
        .catch((e) => {});
    },
    save() {
      if (this.product.id == null) this.insert();
      else this.update();
    },
    insert() {
      this.isSending = true;
      axios
        .post(this.$store.getters.getEnvironment + "product/", this.product)
        .then((response) => {
          this.product.id = response.data.data.id;
          this.isSending = false;
          this.$store.commit("setProductsKey");
          this.$store.commit("setLotNumbersKey");
          if (this.currentPage == "Products") {
            this.load();
            return;
          }
          this.close();
        })
        .catch((e) => {});
    },
    update() {
      this.isSending = true;
      axios
        .put(
          this.$store.getters.getEnvironment + "product/" + this.product.id,
          this.product
        )
        .then((response) => {
          this.product.id = response.data.data.id;
          this.isSending = false;
          this.$store.commit("setProductsKey");
          this.$store.commit("setLotNumbersKey");
          this.close();
        })
        .catch((e) => {});
    },
    openLotNumberDetail(value) {
      this.$store.commit("showModalSecond", {
        componentName: "LotNumberDetail",
        parameter: { id: value, productId: this.product.id },
      });
    },
    close() {
      this.$store.commit("hideModal");
    },
    validateProductExistence() {
      if (!!this.product.name && !!this.product.potency) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          var params = `?name=${this.product.name}&potency=${this.product.potency}`;
          axios
            .get(this.$store.getters.getEnvironment + "product" + params)
            .then((res) => {
              this.productExists = res.data.length;
              this.$refs["productName"].validate();
            });
        }, 300);
      }
    },
  },
  created() {
    this.onSetTitle(this.$t("product.viewName"));
    this.loadFactorType();
  },
};
</script>
