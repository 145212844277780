var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"tile":"","elevation":"0","height":"100%","min-height":"100%"}},[_c('v-form',{ref:"form",model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-card-text',{staticClass:"pb-0"},[_c('v-text-field',{ref:"productName",staticClass:"pb-2",attrs:{"label":_vm.getTranslation('product.creationForm.formFieldTitles.name', '*'),"rules":[
          function () { return !!_vm.product.name || _vm.$t('product.creationForm.errors.nameRequired'); },
          function () { return !_vm.productExists || _vm.$t('product.creationForm.errors.nameTaken'); } ],"required":"","outlined":"","dense":"","hide-details":"","disabled":!_vm.canEditProductName},on:{"keydown":_vm.validateProductExistence},model:{value:(_vm.product.name),callback:function ($$v) {_vm.$set(_vm.product, "name", $$v)},expression:"product.name"}}),_c('v-text-field',{staticClass:"pb-2",attrs:{"hide-details":"","label":_vm.getTranslation('product.creationForm.formFieldTitles.potency', '*'),"rules":[
          function () { return !!_vm.product.potency ||
            _vm.$t('product.creationForm.errors.potencyRequired'); } ],"required":"","type":"number","outlined":"","disabled":!!_vm.product.id,"dense":""},on:{"keydown":_vm.validateProductExistence},model:{value:(_vm.product.potency),callback:function ($$v) {_vm.$set(_vm.product, "potency", $$v)},expression:"product.potency"}}),_c('v-text-field',{staticClass:"pb-2",attrs:{"hide-details":"","label":_vm.getTranslation(
            'product.creationForm.formFieldTitles.manufacturer',
            '*'
          ),"rules":[
          function () { return !!_vm.product.manufacturer ||
            _vm.$t('product.creationForm.errors.manufacturerRequired'); } ],"required":"","outlined":"","disabled":!!_vm.product.id,"dense":""},model:{value:(_vm.product.manufacturer),callback:function ($$v) {_vm.$set(_vm.product, "manufacturer", $$v)},expression:"product.manufacturer"}}),_c('v-select',{staticClass:"pb-2",attrs:{"hide-details":"","items":_vm.factorTypes,"label":_vm.getTranslation(
            'product.creationForm.formFieldTitles.factorType',
            '*'
          ),"required":"","rules":[
          function () { return !!_vm.product.factorTypeId ||
            _vm.$t('product.creationForm.errors.factorTypeRequired'); } ],"item-text":"name","item-value":"id","outlined":"","disabled":!!_vm.product.id,"dense":""},model:{value:(_vm.product.factorTypeId),callback:function ($$v) {_vm.$set(_vm.product, "factorTypeId", $$v)},expression:"product.factorTypeId"}}),_c('div',{staticClass:"d-flex pb-2"},[_c('v-spacer'),(_vm.showSaveButton)?_c('v-btn',{attrs:{"color":"success","disabled":_vm.isSending || !_vm.isValid},on:{"click":function($event){return _vm.save()}}},[_vm._v(_vm._s(_vm.$t("actions.save")))]):_vm._e(),(_vm.isSending)?_c('v-progress-circular',{attrs:{"indeterminate":""}}):_vm._e()],1)],1),_c('v-divider'),_c('v-card-text',[_c('div',{staticClass:"d-flex"},[_c('h2',{staticClass:"pt-2",staticStyle:{"width":"50%"}},[_vm._v("Lot details")]),_c('v-text-field',{staticClass:"pb-2",staticStyle:{"width":"50%"},attrs:{"label":_vm.$t('actions.filters'),"append-icon":"mdi-magnify","hide-details":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.showLotNumbersTable)?_c('v-data-table',{key:this.$store.state.lotKey,attrs:{"headers":_vm.headers,"items":_vm.lots,"sort-by":['name'],"loading":_vm.isDataLoading,"loading-text":_vm.$t('app.loading'),"footer-props":{
          'items-per-page-options': [10, 20, 30, 40, 50, -1],
        },"items-per-page":this.$store.state.itemsPerPage,"search":_vm.search,"dense":""},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openLotNumberDetail(item.lotNumberId)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}},{key:"item.expiryDate",fn:function(ref){
        var item = ref.item;
return [_vm._v(_vm._s(_vm._f("moment")(item.expiryDate,"YYYY-MM-DD")))]}},{key:"item.country",fn:function(ref){
        var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.country)}})]}}],null,false,548286133)}):_vm._e()],1)],1),_c('v-snackbar',{staticStyle:{"top":"8px"},attrs:{"top":"","color":_vm.showSnackbarColor,"timeout":3000},model:{value:(_vm.showSnackbar),callback:function ($$v) {_vm.showSnackbar=$$v},expression:"showSnackbar"}},[_vm._v(" "+_vm._s(_vm.snackBarMessage)+" "),_c('v-btn',{attrs:{"color":"white","text":""},on:{"click":function($event){_vm.showSnackbar = false}}},[_vm._v("CLOSE")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }